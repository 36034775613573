@import './variables.scss';
// $add-actions-width: 98px;
.editor-container {
  height: 74vh;
  overflow: scroll;
}
.editor-actions {
  width: 100%;
  height: 5vh;
  > button {
    margin-right: 1em;
  }
}
.add-action-menu {
  padding: 0.5em;
  .add-action-btn {
    margin-bottom: 1em;
    button {
      width: 100%;
      align-items: center;
      font-size: 12px;
      justify-content: start;
    }
    &:last-child {
      margin: 0;
    }
  }
}
.avatar-uploader {
  width: 100%;
  .ant-upload.ant-upload-select {
    width: 100% !important;
    height: 50px !important;
  }
}
.ant-select-multiple {
  font-size: 12px !important;
}

.text-editor {
  .tiptap {
    border: 1px solid $light-grey;
    border-radius: 8px;
    margin: 1em 0 2em;
    padding: 1em;
    &.ProseMirror-focused {
      border: 1px solid $primary;
    }
  }
  code {
    font-family: inherit;
    background-color: #eaeaea;
    padding: 0.4em 0.5em;
    border-radius: 5px;
    &::before {
      content: '\2611';
      font-size: 1.9em;
      margin-right: 0.5em;
    }
  }
  pre {
    margin: 0;
    code {
      background-color: $light-blue;
    }
  }
  blockquote {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    background-color: #eaeaea;
    padding: 0.2em 0.5em;
    border-radius: 5px;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    p {
      margin: 0;
    }
  }
  &.row-value {
    .tiptap {
      margin: 0;
    }
  }
}
.formatter-btns {
  margin-top: 2em;

  button {
    margin-right: 1em;
  }
}
.format-tools {
  height: 0;
  overflow: hidden;
  background: #e3e5e7;
  margin-bottom: 2px;
  transition: 250ms ease all;
  border-radius: 5px 5px 0 0;
  padding: 0 1em;
  &.open {
    height: 27px;
  }
  svg {
    font-size: 1.8em;
  }
  button {
    height: 20px !important;
    margin: 0.3em 0.7em 0 0;
  }
}

@media screen and (max-width: 480px) {
  .editor-actions {
    flex-direction: row;
    padding: 0;
    button {
      margin-right: 1em;
    }
  }
}
