@import './variables.scss';

.logo {
  padding: 0.8em;
  width: 100%;
  text-align: left;
  .yarn,
  .werk {
    font-family: 'IBM Plex Mono', monospace;
    margin: 0;
    // color: $light-primary;
  }
  .yarn {
    font-size: 1.3em;
  }
  .werk {
    font-size: 2em;
    font-weight: 700;
    margin-top: -7px;
  }
  img {
    width: 25px;
    margin-right: 10px;
  }
}

.sider {
  // border: 1px solid $light-blue;
  box-shadow: -2px -1px 13px -1px rgb(219, 235, 255);
  // border-radius: 20px;
  // height: calc(100vh - 35px) !important;
  // margin: 13px 7px;
  svg {
    color: $secondary;
  }

  .ant-divider {
    margin: 0;
  }
}

.header {
  height: 45px;
  display: flex;
  padding: 0 0.4em;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  box-shadow: -6px -14px 27px 0px #e5e5e5;
  svg {
    color: $primary;
  }
  > div {
    width: 100%;
  }
  .ant-divider {
    margin: 0;
  }
}

.header-menu {
  padding: 0.5em;
}
.menu-item {
  min-width: 150px;
  // border-bottom: 1px solid $light-blue;
  button {
    height: auto;
    padding: 0.7em 0.5em;
    width: 100%;
    justify-content: start;
    font-size: 1.1em;
  }
  &:last-child {
    border: none;
  }
}
.sign-out-btn {
  // width: 100%;
  position: fixed;
  bottom: 10px;
  left: 20px;
}

@media screen and (max-width: 768px) {
  .logo {
    padding: 0.3em;
    img {
      width: 20px;
    }
    .yarn {
      font-size: 1em;
    }
    .werk {
      font-size: 1.6em;
      margin-top: -6px;
    }
  }
  .menu-item button {
    padding: 0.3em 0;
  }
  .header svg {
    color: $secondary;
  }
}
