@import './variables.scss';

.project-view {
  padding: 0 2em 1em;
  max-width: 950px;
  margin: 0 auto;
  height: 95vh;
}
.project-header {
  padding-bottom: 1em;
  // border-bottom: 1px solid #cfdddf;
  color: #152c2f;
  height: 10vh;
  h1,
  .project-name-input {
    width: 98%;
    margin-left: 1%;
  }
  .project-menu {
    height: auto;
    margin-top: 4px;
  }
  .project-name-input {
    height: 44px;
    font-size: 1.5em;
  }
}

.ant-tabs-tab {
  border-color: $light-blue !important;
  border-width: 1px 1px 0 !important;
}
.tab-selector {
  height: 5vh;
  padding: 0 0.5em;
  width: 185px;
  background: #fff;
  border-radius: 0.4em 0.4em 0 0;
  border: 1px solid $light-blue;
  margin-bottom: -1px;
  z-index: 10;
  position: sticky;
  .tab-input {
    width: 100%;
    .ant-select-selector {
      border: none !important;
      color: $secondary;
      .ant-select-selection-item {
        font-weight: bold;
        font-size: 1em;
      }
    }
  }
}

.tab-container {
  overflow-y: scroll;
  height: 81vh;
  width: 100%;
  padding: 1em 1em 2em;
  background: #fff;
  border: 1px solid $light-blue;
  border-radius: 0 20px 20px 20px;
  &.edit-mode {
    overflow-y: inherit;
  }

  .row-display {
    display: flex;
    padding: 0.5em;
    border-radius: 0.5em;
    align-items: center;
    p {
      margin: 0 0 10px;
    }
    &.hover {
      cursor: pointer;
      &:hover {
        background-color: $light-primary-bg;
      }
    }
    text-align: left;

    .row-label {
      width: 20%;
      margin-right: 10px;
      font-weight: bold;
      &.done {
        color: #9b9b9b;
      }
      input {
        font-weight: bold;
      }
    }
    .row-value {
      width: 80%;
      &.done {
        // text-decoration: line-through;
        color: #9b9b9b;
      }
      &.no-label {
        width: 96%;
      }
      &.ant-input.header-input {
        font-size: 1.7em;
      }
    }
    .row-image {
      width: 96%;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .delete-btn {
      width: 2%;
      margin-left: 2%;
    }
    .is-done-cb {
      width: 5%;
      margin-right: 10px;
    }
    &.sortable {
      cursor: move;
      .row-value,
      .row-label {
        padding: 4px 11px;
        border: 1px solid $light-grey;
        border-radius: 0.5em;
        background: $light-grey;
      }
    }
  }
  .add-row-above {
    width: 96%;
    height: 5px;
    overflow: hidden;
    opacity: 0;
    transition: 250ms ease all;
    .line {
      width: calc(50% - 160px);
      height: 1px;
      border-top: 1px solid $light-blue;
    }
    .actions {
      button {
        margin: 0 0.5em;
      }
    }
    &:hover {
      height: 25px;
      opacity: 1;
    }
  }
  &.has-headers {
    padding-top: 0;
  }
}

$project-nav-width: 150px;
.project-nav {
  background: #fff;
  border-right: 1px solid $light-blue;
  .ant-flex button {
    margin-top: 0.5em;
  }
  .headers {
    button {
      width: 98%;
      justify-content: start;
      margin: 0 1% 0.7em;
      font-weight: bold;
      padding: 0 0.5em;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.reset-checkmarks {
  display: flex;
  justify-content: end;
  border-top: $light-blue 1px solid;
  height: 45px;
  padding-right: 1em;
}

.modal-footer {
  margin-top: 2em;
  justify-content: end;
  button {
    margin-left: 1em;
  }
}

.ant-tabs-tabpane {
  padding: 0 !important;
}

.project-tags {
  padding: 0 0.5em;
  margin-top: 1em;
  .ant-select {
    width: 100%;
  }
  > p {
    font-weight: 600;
    margin: 0 0 0.5em;
  }
}

.tag-item {
  align-items: center;
  display: flex;
  background: $light-primary-bg;
  color: $secondary;
  border-color: $light-primary-bg;
  &.no-tags {
    background: $light-blue;
    font-weight: normal;
    color: grey;
  }
  svg {
    margin-right: 5px;
  }
}

.finish-next-row {
  margin-bottom: -2em;
  padding-right: 1em;
}

.tool-container {
  z-index: 100;
  position: fixed;
  right: 10px;
  transition: 250ms ease all;
  margin-top: -6px;
  padding: 0.5em;
  background: #ffffff;
  border-radius: 0.4em 0.4em 0 0;
  border: 1px solid $light-blue;
  border-bottom: none;
  &.open {
    border-bottom: 1px solid $light-blue;
    padding: 1em;
    height: 52px;
  }
  .timer {
    color: $secondary;
    > span {
      margin-left: 15px;
      font-size: 1.3em;
      width: 90px;
      text-align: left;
    }

    svg {
      font-size: 1.2em;
    }
  }
}

@media screen and (max-width: 480px) {
}
