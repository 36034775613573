@import './variables.scss';
#root {
  height: 100vh;
  overflow: hidden;
}
body,
h1,
h2,
h3,
.icon-name {
  font-family: 'Noto Sans JP', sans-serif;
}
main {
  height: 100vh;
  // background-color: #f5f5f5;
}

h3 {
  margin: 0 0 0.5em;
}
h2,
h1 {
  margin: 0;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

input {
  padding: 0.3em;
  border: 1px solid #c6c6c6;
  border-radius: 0.3em;
}

.container {
  padding: 1em 1.5em;
  transition: 250ms ease all;
}

.not-found {
  margin-top: 4em;
  color: grey;
}

.ant-card-bordered {
  border: 1px solid #d6d6d6;
}

.breadcrumbs {
  padding-right: 0.7em;
  border-bottom: 1px solid $light-blue;
  height: 5vh;
  .bread-crumbs {
    font-size: 12px;
    padding: 0.5em 1.1em;
    ol {
      align-items: center;
      li:last-child {
        span {
          font-weight: bold;
        }
      }
      a,
      li > span {
        display: flex;
        align-items: center;
        svg {
          margin-right: 5px;
        }
      }
    }
  }
}

.ant-menu-item,
.ant-menu-submenu-title {
  padding-left: 10px !important;
}

.custom-dropdown {
  display: flex;
  flex-direction: column;
  padding: 0.4em;
  button {
    justify-content: start;
  }
}
.loading-container {
  width: 100%;
  height: 100%;
  background-color: $light-primary;
}
.tiptap {
  :first-child {
    margin-top: 0;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 0.6em;
  }
}
