@import './variables.scss';
.thumbnail {
  width: 100%;
  background-color: #fff;
  border-radius: 0.8em;
  margin-bottom: 1em;
  cursor: pointer;
  border: 1px solid $light-blue;
  transition: all 300ms ease;
  &.flat {
    box-shadow: none;
    border: 1px solid $light-blue;
  }
  &.no-click {
    cursor: auto;
    &:hover {
      background-color: #fff;
    }
  }
  &:hover {
    background-color: $light-primary-bg;
    border-color: $secondary;
  }
  .icon-name {
    padding: 0.8em;
    width: 95%;
    align-items: center;
    span {
      font-size: 1em;
      margin-left: 10px;
      font-weight: 600;
    }
    svg {
      color: $secondary;
    }
  }
  .btn {
    height: 20vh;
  }
  &.add {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3e879c;
    svg {
      font-size: 4em;
      color: #fff;
    }
  }

  .projects-in-folder {
    .icon-name {
      padding: 0 0 0.5em;
      margin-left: 3em;
    }
  }
}
.thumbnail-container {
  margin: 1em 0;
  .thumbnail {
    width: 23%;
    margin: 0 1% 2em;
    background-color: #e5efff;
    border: none;
  }
}

.add-new-project {
  width: 100%;
  font-size: 1.5em;
  padding: 1.4em 0;
  border: 2px dashed $light-blue;
  color: $primary;
  background-color: transparent;
}

.thumbnail-category {
  margin: 0 0 1em;
  padding-left: 1em;
  font-weight: bold;
  color: #626262;
}

@media screen and (max-width: 480px) {
  .thumbnail-container {
    .thumbnail {
      width: 100%;
      margin: 0 0 1em;
    }
  }
}
