@import './variables.scss';

.login-container {
  width: 100%;
  height: 100vh;
  background: hsla(201, 92%, 53%, 1);
  background: linear-gradient(45deg, hsl(201deg 38.09% 6.49%) 1%, hsl(204deg 87.96% 29.07%) 100%);
  background: -moz-linear-gradient(45deg, hsl(201deg 38.09% 6.49%) 1%, hsl(204deg 87.96% 29.07%) 100%);
  background: -webkit-linear-gradient(45deg, hsl(201deg 38.09% 6.49%) 1%, hsl(204deg 87.96% 29.07%) 100%);
  h3 {
    margin: 0;
  }
  .logo {
    width: 100%;
    color: $light-primary;
    .yarn {
      font-size: 2.1em;
    }
    .werk {
      font-size: 3em;
    }
    img {
      width: 50px;
    }
  }
  .ant-input-outlined {
    margin-bottom: 1em;
    background: transparent;
    color: $light-primary; //$secondary;
    transition: 250ms ease all;
    border-color: $light-primary; //$secondary;
    &:hover,
    &:focus {
      border-color: $light-primary; //$secondary;
    }
    svg {
      color: $light-primary; //$secondary;
    }
    ::placeholder {
      color: $light-primary; //$secondary;
      opacity: 0.7; /* Firefox */
    }
    &.ant-input-status-error {
      background: transparent;
    }
  }

  .login-content {
    width: 50%;
    h1 {
      color: $light-primary; //$secondary;
      margin-bottom: 1em;
    }
    .action-btn {
      width: 100%;
      background-color: $light-primary; //$secondary;
      color: #0c2332;
      font-weight: 700;
      opacity: 0.8;
      &:hover {
        opacity: 1;
        color: #0c2332;
        background-color: $light-primary; //$secondary !important;
      }
      &:disabled {
        opacity: 0.4;
      }
    }
  }

  .login-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
    color: $light-primary; //$secondary;
    button {
      color: $light-primary; //$secondary;
      opacity: 0.8;
      &:hover {
        // font-weight: 700;
        opacity: 1;
        color: $light-primary !important; //$secondary !important;
      }
    }
  }

  .pw-hint {
    width: 100%;
    color: $light-primary;
    font-size: 0.8em;
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 480px) {
  .login-container {
    .login-logo {
      width: 200px;
    }
    .login-content {
      width: 80%;
    }
  }
}
