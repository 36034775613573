.home {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.polaroids {
  display: flex;
  .evancrying1,
  .evancrying2 {
    width: 220px;
    box-shadow: -4px 5px 11px -3px rgba(133, 133, 133, 0.67);
  }
  .evancrying1 {
    margin-right: 20px;
  }

  .frame {
    width: 315px;
    height: 499px;
  }
}
.thanks {
  text-align: center;
  font-family: 'Playfair Display', serif;
}

@media (max-width: 428px) {
  h1 {
    font-size: 2em;
  }
  .polaroids {
    .evancrying1,
    .evancrying2 {
      width: 150px;
    }
  }
}
@media (max-width: 350px) {
  .polaroids {
    .evancrying1,
    .evancrying2 {
      width: 125px;
    }
  }
}
