@import './variables.scss';

.user-profile-btn {
  // color: $primary;
  padding: 1.5em 0.2em;
  text-align: left;
  // position: fixed;
  // bottom: 20px;
}
.name-email {
  font-size: 1.1em;
  div:last-child {
    font-size: 0.8em;
  }
}

.edit-user-title {
  svg,
  h3 {
    color: $primary;
  }
  h3 {
    margin: 0 0 0 5px;
  }
}

.user-profile-editor {
  margin: 1em 0;
  .ant-input-outlined {
    margin-bottom: 1em;
  }
}
.pw-editor {
  margin: 0.5em 0 1em;

  .pw-hint {
    color: grey;
    font-size: 0.8em;
  }
}
.user-dropdown .custom-dropdown {
  padding: 0.8em;
}

.user-menu-item {
  width: 200px;
  color: $primary;
  margin: 0.5em 0;
  svg {
    margin-right: 5px;
  }
  .ant-divider {
    margin: 0.5em 0 0;
  }
  &.ant-btn {
    width: 100%;
    span {
      color: #fff;
    }
    &.signout span {
      color: grey;
      // background-color: grey;
    }
  }
}
@media screen and (max-width: 768px) {
  .user-profile-btn {
    position: initial;
    margin-right: 1em;
  }
  .name-email {
    padding: 0 1em 0.5em;
    div:last-child {
      font-size: 0.8em;
    }
  }
}
