@import './variables.scss';

.search-container {
  width: 96%;
  padding: 0.3em 0.2em;
  border: 1px solid #d7e7f2;
  background-color: #fff;
  border-radius: 10px;
  margin: 1em 2%;
  .search-input {
    border: none;
  }
  svg {
    color: $primary;
  }
}

.sort-and-filters {
  // height: 0;
  transition: 250ms ease all;
  overflow: hidden;
  margin: 0.5em 2%;
  &.open {
    height: 30px;
  }
  .sort-select {
    min-width: 120px;
    margin-right: 1.5em;
    margin-left: -5px;
    .ant-select-selector {
      font-size: 12px;
    }
  }
  .filter-select {
    min-width: 200px;
    margin-left: 5px;
  }
  svg {
    color: $primary;
  }
}
