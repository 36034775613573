@import './variables.scss';

.abbrs-modal-container {
  margin-top: 1em;
  .ant-select {
    width: 100%;
    margin-bottom: 1em;
  }
}
.project-selection {
  flex: 1;
  border-right: 1px solid $light-blue;
  padding: 0 0.5em;
  button {
    margin-bottom: 0.7em;
  }
}

.abbrs-container {
  padding: 0 1em;
  flex: 8;
  height: 400px;
  overflow: scroll;
  .abbr {
    span {
      flex: 8;
      &:first-child {
        font-weight: bold;
        flex: 2;
      }
    }
    margin-bottom: 0.5em;
  }
}
